.btn-primary{
    color:white;

    &:hover, &:focus, &:active, &.active{
        color:white !important;
    }
}

.btn-choice {
    --bs-btn-font-weight: 500;
    --bs-btn-bg: var(--bs-white);
    --bs-btn-border-radius: .5rem;
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: black;
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
    --bs-btn-active-color: var(--bs-primary);
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: var(--bs-primary);
    border-width: 2px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    position: relative; /* Ensure the button container is relative */
    transition: color 0.10s ease-in-out, background-color 0.10s ease-in-out, border-color 0.10s ease-in-out, box-shadow 0.10s ease-in-out; /* Apply transitions to all properties */
  }
  
  .btn-choice::after {
    content: ''; /* Create the badge */
    position: absolute; /* Position the badge absolutely */
    top: -5px; /* Adjust this value to position the badge vertically */
    right: -5px; /* Adjust this value to position the badge horizontally */
    width: 20px; /* Set the width of the badge */
    height: 20px; /* Set the height of the badge */
    background-color: var(--bs-primary); /* Set the background color of the badge */
    border-radius: 50%; /* Make the badge round */
    opacity: 0; /* Initially hide the badge */
    transition: opacity 0.15s ease; /* Apply transition to opacity */
  }
  
  .btn-choice::before {
    content: '\2713'; /* Unicode for checkmark icon */
    position: absolute; /* Position the icon absolutely */
    top: -10%; /* Align the icon vertically */
    right: -1%; /* Align the icon horizontally */
    color: var(--bs-white); /* Set the color of the icon */
    font-size: 14px; /* Set the size of the icon */
    z-index: 1024;
    opacity: 0; /* Initially hide the icon */
    transition: opacity 0.1s ease; /* Apply transition to opacity */
  }
  
  .btn-choice.active::after,
  .btn-choice.active::before {
    opacity: 1; /* Fade in the badge and icon on hover */
  }
  
  .btn-check:checked + .btn-choice:after,
  .btn-check:checked + .btn-choice:before {
    opacity: 1; /* Fade in the badge and icon on hover */
  }
  
