@font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: bolder;
}

@font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: bold;
}


body{
    font-family: 'Inter';
}

ng-icon {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

app-root {
  display: flex;
  overflow-x: hidden;

}

app-sidebar {
  height: 100%;
}

.ng-touched.ng-invalid {
  @extend .is-invalid;
}

form.ng-submitted{
  .ng-invalid {
    @extend .is-invalid;
  }
}
