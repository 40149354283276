$primary: #0099ff;
$body-bg: #f9fafb;
$body-bg: #f6fafd;
$body-color: #34495e;



$component-active-color: darken($primary, 10%);

$component-active-bg: #dfedfb;

$border-radius: 1.5rem;

$font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;

$font-size-base: 1rem;

$nav-link-color: $body-color;
$nav-link-hover-color: $primary;
$nav-link-font-weight: 500;

$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 1.25rem;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
);
